import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import "./products.scss";
import LeftImageBox2 from "../common/LeftImageBox2";
import RightImageBox2 from "../common/RightImageBox2";
import cp2 from "../../images/cp2.png";
import cp2lte from "../../images/cp2lte.png";
import cp4 from "../../images/cp4.png";
import crx from "../../images/crx.png";
import product from "../../images/product.mp4";
import Banner2 from "../Banner2";

class Products extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                id="dispatchWrapper"
                style={{ padding: 0 }}
                fluid={true}
            >
                <Banner />
                <Header curr={"dispatch"} />
                <video
                    src={product}
                    controls
                    autoPlay
                    style={{ marginBottom: 50, width: "100%" }}
                />
                <h1 className="h1" style={{ marginTop: 40, marginBottom: 40 }}> PRODUCTS</h1>
                <RightImageBox2 image={cp2} title="2 CHANNEL OVERVIEW"
                   shadow={false}
                    alt="Easy load management trucking software"
                    desc=" The Osrit Dashcam 2 channel is the world’s most advanced incident camera
                     with powerful 3G (or Wi-Fi) video transmission, built-in tracking and telematics data. 
                    Developed to provide evidence of events leading up to and during a 
                    road traffic accident, the 2 channel provides instant notifications & videos of incidents in less than a minute. 
                    2 channel features a 1080p wide angle HD camera, 6-Axis G-Sensor/Gyro, 
                    Microphone, removable SD storage (up to 128GB), cellular modem, 
                    integrated GPS/Glonass receiver,
                     permanent power cable with optional vehicle input sensors,
                      & a tamper-resistant design. An optional 2nd camera ('plug & play') can be connected to the main unit for recording the driver/vehicle interior."
                    link={"/products/2-channel"}
                />
                <LeftImageBox2 image={cp2lte} title="2 CHANNEL(LTE) OVERVIEW"
                    shadow={false}
                    alt="load information overload servive"
                    desc="The SmartWitness 2 channel(LTE) is the world’s most advanced incident camera with powerful 4G/LTE (or Wi-Fi) video transmission, built-in tracking and telematics data. Developed to provide evidence of events leading up to and during a road traffic accident, the 2 channel(LTE) provides instant notifications & videos of incidents in less than a minute.CP2 features a 1080p wide angle HD camera, 6-Axis G-Sensor/Gyro, Microphone, removable SD storage (up to 128GB), cellular modem, integrated GPS/Glonass receiver, permanent power cable with optional vehicle input sensors, & a tamper-resistant design. An optional 2nd camera ('plug & play') can be connected to the main unit for recording the driver/vehicle interior."
                    link={"/products/2-channel-lte"}
                />
                <RightImageBox2 image={cp4} title="4 CHANNEL OVERVIEW"
                   shadow={false}
                    alt="truck load realtime update"
                    desc="The Osrit Dashcam 4 channel is the world’s smallest 4 channel vehicle data recorder.4 channel is an LTE enabled device which offers commercial vehicle owners an effective risk reducing means to aid in the investigation process, decrease collateral damages associated with car accidents, encourage safe driving, monitor driving behaviours, and enhance the overall safety on the road. 4 channel features 4 camera inputs for connecting 1, 2, 3, or 4 cameras. A 6-Axis G-Sensor, Microphone, SD card (up to 128GB capacity), panic button, embedded LTE modem, & GPS.4 channel is powered up on vehicle ignition and automatically begins recording. Various camera options are available, making the amount of possible 4 channel system combinations nearly endless.An LCD display can be connected to 4 channel, making it double as a rear view backup system and blind-spot aid."
                    link={"/products/4-channel"}
                />
                <LeftImageBox2 image={crx} title="8 CHANNEL OVERVIEW"
                    shadow={false}
                    alt="best weather app for driving weather report"
                    desc="The Osrit Dashcam 8 channel offers High Definition in-vehicle CCTV recording for up to 8 cameras. 8 channel provides fleet operators with an effective way to monitor and manage their remote assets whilst in the field. In the event of an incident and/or allegation the 8 channel HD mobile will aid in the investigation process whilst reducing potential cost and exposure. In-Vehicle CCTV delivers actual video footage in the event of a claim, encourages safer more fuel-efficient driving, monitors driving behaviours and enhances the overall safety and security for the driver, the fleet operator and other road users.The solution includes a built-in 3-Axis G-Sensor, GPS, 8 alarm input triggers, flexible and powerful storage using an SSD (up to 1TB) and SD Card backup storage (up to 128GB). The 8 channel is slim and compact, only 7 x 7 x 2 and is connected directly to the vehicle's battery power and ignition for fully automated operation. Optionally connect a dashboard mounted LCD display to provides the driver with a real-time 360° view of the interior and exterior of the vehicle."
                    link={"/products/8-channel"}
                />
                <Banner2 />
            </Container>
        );
    }
}

export default Products;
