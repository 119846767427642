import * as TYPES from '../actions/localeActionTypes';

export default function locales(state = {}, action) {
    switch (action.type) {
        case TYPES.HOMES_LOCALES:
            return {
                ...state,
                home: action.value
            }
        default:
            return state
    }
}
