import React from 'react';

class Content extends React.Component {
    render() {
        return (<div className="terms_content" style={{ textAlign: "justify" }}>
            <h3 className="text-center terms_header">Terms of Service</h3>

            <div>By accessing the website at www.osrit.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</div>

            <h4 className="terms_title">User Account</h4>
            <div>You may need an Asritsolutions LLC Account in order to use some of our Services. You may create your own Asritsolutions LLC Account, or your Osrit Account may be assigned to you by an administrator, such as your company owner. If you are using an Asritsolutions LLC Account assigned to you by an administrator, different or additional terms may apply and your administrator may be able to access or disable your account. To protect your Asritsolutions LLC Account, keep your password confidential. You are responsible for the activity that happens on or through your Asritsolutions LLC Account. Try not to reuse your Asritsolutions LLC Account password on third-party applications. If you learn of any unauthorized use of your password or Asritsolutions LLC Account, contact us immediately.</div>

            <h4 className="terms_title">Privacy and Copyright Protection</h4>
            <div>Asritsolutions LLC's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that Osrit can use such data in accordance with our privacy policies. We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.</div>

            <h4 className="terms_title">Your Content in our Services</h4>
            <div>Some of our Services allow you to upload, submit, store, send or receive content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours. When you upload, submit, store, send or receive content to or through our Services, you give Osrit (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services. Some Services may offer you ways to access and remove content that has been provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services.</div>

            <h4 className="terms_title">Use License</h4>
            <div>Permission is granted to temporarily download one copy of the materials (information or software) on Asritsolutions LLC's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not : modify or copy the materials; use the materials for any commercial purpose, or for any public display (commercial or non-commercial); attempt to decompile or reverse engineer any software contained on Asritsolutions LLC's website; remove any copyright or other proprietary notations from the materials; or transfer the materials to another person or "mirror" the materials on any other server. This license shall automatically terminate if you violate any of these restrictions and may be terminated by Asritsolutions LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</div>

            <h4 className="terms_title">Disclaimer</h4>
            <div>The materials on Asritsolutions LLC's website are provided on an 'as is' basis. Asritsolutions LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, Asritsolutions LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</div>

            <h4 className="terms_title">Limitations</h4>
            <div>In no event shall Asritsolutions LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Asritsolutions LLC's website, even if Asritsolutions LLC or an Asritsolutions LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</div>

            <h4 className="terms_title">Accuracy of materials</h4>
            <div>The materials appearing on Asritsolutions LLC's website could include technical, typographical, or photographic errors. Asritsolutions LLC does not warrant that any of the materials on its website are accurate, complete or current. Asritsolutions LLC may make changes to the materials contained on its website at any time without notice. However Asritsolutions LLC does not make any commitment to update the materials.</div>

            <h4 className="terms_title">Liability</h4>
            <div>When permitted by law, Asritsolutions LLC, will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages. To the extent permitted by law, the total liability of Asritsolutions LLC, for any claims under these terms, including for any implied warranties, is limited to the amount you paid us to use the services (or, if we choose, to supplying you the services again). In all cases, Asritsolutions LLC, will not be liable for any loss or damage that is not reasonably foreseeable.</div>

            <h4 className="terms_title">Links</h4>
            <div>Asritsolutions LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Asritsolutions LLC of the site. Use of any such linked website is at the user's own risk.</div>

            <h4 className="terms_title">Modifications</h4>
            <div>Asritsolutions LLC may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service. We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether. You can stop using our Services at any time, although we’ll be sorry to see you go. Asritsolutions may also stop providing Services to you, or add or create new limits to our Services at any time. We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</div>

            <h4 className="terms_title">Governing Law</h4>
            <div>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</div>

        </div>)
    }
}

export default Content;
