import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import RowFour from "./RowFour";
import "./home.scss";

class Home extends React.Component {
    componentDidMount() {
        if (window.location.pathname === "/contact-us") {
            this.pageEnd.scrollIntoView({ behavior: "smooth" });
        }
    }
    render() {
        return (
            <div id="homeWrapper">
                <Banner />
                <Header curr={"home"} />
                <RowFour />
            </div>
        );
    }
}

export default Home;
