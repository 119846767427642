import axios from 'axios';

const csrf = document.getElementById('csrf');

axios.defaults.headers.common['x-csrf-token'] = csrf && csrf.value;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.timeout = 20000;

export function get(url, params) {
    return axios.get(url, {params}).then((response) => response.data)
};

export function post(url, params) {
    return axios.post(url, params).then((response) => response.data)
};

export function put(url, params) {
    return axios.put(url, params).then((response) => response.data)
};
