let _initialData = {};
let isDev = true;
let ip = '';

try {
    ip = document.getElementById('ipAddress').innerHTML
} catch (e) {
    console.log('e :: ', e);
}

const initialData = {
    app: {
        ip,
        isDev,
        version: _initialData.version,
        stripeKey: _initialData.stripeKey,
        appBusy: false,
        user: {
            // email: 'md@gmail.com',
            // password: 'Fremont94536',
            // firstname: 'Maninder',
            // lastname: 'Singh',
            // businessname: 'ABC Company'
        }
    },
    locales: {
        home: {
            cards: {
                carrier: {
                    header: 'Carriers',
                    title: 'Stay organized. Stay paid.',
                    detail: 'Manage everything from IFTA to driver, owner operator settlements and invoicing, all within one web platform.'
                },
                broker: {
                    header: 'Brokers',
                    title: 'Streamline your back office. Earn more.',
                    detail: 'Manage loads, carrier payables, agent offices, payroll, and invoicing to be professional and profitable.'
                },
                ownerOperator: {
                    header: 'Owner Operators',
                    title: 'Stay organized. Stay paid.',
                    detail: 'Manage everything from IFTA to settlements and invoicing, all within one web platform.'
                }
            }
        }
    }

};

const serverProps = {
    ...initialData
};

export default serverProps;
