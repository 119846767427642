import React from "react";
import { Container } from "reactstrap";
import "./home.scss";
import LeftImageBox2 from "../common/LeftImageBox2";
import RightImageBox2 from "../common/RightImageBox2";
import { Row, Col, Card, CardBody } from "reactstrap";
import cp42 from "../../images/cp42.png";
import alerts from "../../images/alerts.png";
import video2 from "../../images/green1.mp4";
import lteImage from "../../images/LTE.png";
import hdImage from "../../images/hd.png";
import liveImage from "../../images/live.png";
import secureImage from "../../images/secure.jpg";
import RowOne from "./RowOne";
import Banner2 from "../Banner2";
import TextWithTitle from "../common/TextWithTitle";
import dashcamDetails from "../../images/dashcamdetails.png";
import dashcamEvents from "../../images/cam_events.png";

class Dashcam extends React.Component {
    feature = ({ icon, title, desc }, i) => {
        return (
            <div
                key={i}
                className="col-lg-4 col-md-6 col-sm-4 mb-60"
                style={{ paddingTop: 30 }}
            >
                <Row style={{ margin: 0 }}>
                    <Col sm="12" md={{ size: 20, offset: 1 }}>
                        <Row
                            className=" justify-content-center"
                            style={{ margin: 0 }}
                        >
                            <div className="feature-text left-icon">
                                <div className="feature-icon">
                                    <span className="ti-layers-alt" />
                                </div>
                                <Card
                                    style={{
                                        alignItems: "center",
                                        padding: "40px 20px",
                                        minHeight: 220
                                    }}
                                    className="round-card"
                                >
                                    <div className="feature-info">
                                        <h5 className="text-center">{title}</h5>
                                        <CardBody
                                            className=""
                                            style={{
                                                textAlign: "center",
                                                padding: 12
                                            }}
                                        >
                                            {desc}
                                        </CardBody>
                                    </div>
                                </Card>

                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    };
    render() {
        const features = [
            {
                image: "",
                title: "170° Field of View",
                desc:
                    "Osrit Dashcam camera lenses capture the entire front and peripheral views of the road ahead for maximum evidence capture."
            },
            {
                icon: "",
                title: "Cellular Video/Data Transfer",
                desc:
                    "Osrit Dashcam offers cellular-enabled systems which provide instant video and driving data within minutes of an accident or road event."
            },
            {
                icon: "",
                title: "Automated & Secure",
                desc:
                    "Osrit Dashcam products are designed for automatic power-up, operation & shut-down. Our tamper-resistant designs reduce driver interference"
            },
            {
                icon: "",
                title: "Multiple Cameras",
                desc:
                    "Connect up to 8 cameras per vehicle for maximum recording capture and increase blind-spot visibility for drivers."
            },
            {
                icon: "",
                title: "G-Force Sensor",
                desc:
                    "Integrated 3-Axis G-Force sensors provide detailed impact & driving behaviour data. G-Forces auto-calibrate & are customisable per axis."
            },
            {
                icon: "",
                title: "Built-in GPS",
                desc:
                    "GPS provides crucial evidence in all road events such as vehicle location, speed, direction and accurate time/date and driving routes."
            },
            {
                icon: "",
                title: "Audio Recording",
                desc:
                    "Built-in microphones record crisp, clear audio inside the vehicle. Audio recording can be easily turned off in the settings."
            },
            {
                icon: "",
                desc:
                    "From dual SD card redundancy to automatic SD formatting & system health checks, Osrit Dashcam ensures your road events are recorded & retained.",
                title: "Reliability"
            },
            {
                icon: "",
                title: "Telematics Integration",
                desc:
                    "Osrit Dashcam can integrate with 3rd party telematics software (GPS Tracking) for event video management & remotely streaming live video."
            },
        ];

        return (
            <Container id="dashcamWrapper" style={{ padding: 0 }} fluid={true}>
                <video
                    src={video2}
                    controls
                    autoPlay
                    style={{ marginBottom: 50, width: "100%" }}
                />
                <TextWithTitle
                    title="How it Works and Why Osrit Dashcam?"
                    text="Truck and Commercial fleet drivers are found to be at fault at a much higher rate than private car drivers when there is a road incident. Commercial fleet owners and drivers can protect themselves and save time & costs with the concrete evidence Osrit Dashcam technology provides. Osrit Dashcam combines the ease of use and simple form factor of a dash camera with the added security, technology, and feature set of a more expensive mobile DVR."
                />
                <div className="mt-4 text-center text-uppercase">
                    <img src={lteImage} alt="LTE" style={{ height: 60 }} />
                    <img src={secureImage} alt="Secure" style={{ height: 60 }} />
                    <img src={liveImage} alt="Live" style={{ height: 75, }} />
                    <img src={hdImage} alt="HD" style={{ height: 55, marginLeft: 4 }} />
                </div>
                <h3
                    className="text-center text-uppercase h3"
                    style={{ backgroundColor: "#0085ec", color: 'white', padding: 18, }}
                >
                    NOTE: Especially designed for commercial trucks
                </h3>
                <RowOne />
                <hr />
                <LeftImageBox2
                    image={dashcamDetails}
                    title="Real Time Updates"
                    alt="truck dashcam"
                    desc="Osrit Dashcam provides real time updates on driver and truck. You can easliy monitor truck location, speed etc. You can view the live recording in just one click. Improve driver behavior with a combination of in-cab voice alerts, driver rankings, and HD video-based coaching tools."
                />
                <hr />
                <RightImageBox2
                    image={dashcamEvents}
                    title="Event Monitoring"
                    alt="truck dashcam"
                    desc="Monitor all truck events at one place. Here you get all events related to truck, dashcam, trip etc. Easy filter to events by dates. Instantly review collisions, near-misses, and distracted driving events with full HD 1080p footage that is automatically uploaded to the cloud. Using a g-sensor and advanced AI, Osrit Dashcam detects critical events, alerts drivers in real-time, and sends event details to admins."
                />
                <hr />
                <LeftImageBox2
                    image={cp42}
                    title="VIDEO TRACKING"
                    alt="Video tracking throught the dashcam"
                    desc="Our video tracking solution has advanced features such as vehicle location tracking and driver behaviour reports. It is a perfect solution for fleets who require all of the benefits of in-vehicle video and basic GPS tracking. It is also available as a white-label solution for reseller partners and enterprise fleet customers."
                />
                <hr />
                {/* <RightImageBox2
                    image={i3}
                    title="DRIVER ANALYTICS"
                    alt="driver analysics by using dashcam"
                    desc="Our driver analytics solution is a context-aware, behaviour analytics solution that provides deep insight into how and why people drive the way they do. It incorporates powerful contextual behaviour analytics, a purposeful scoring system, and positive reinforcement driver coaching that enables people, organisations, governments, and society to pro-actively increase safety, together with a positive ROI. It utilises the existing drive data captured from our camera systems and is an optional add-on to any of our cloud video solutions. We also have a free app for iOS and Android which utilises GPS and sensor data from your smart phone to provide personal drive reporting."
                />
                <hr /> */}
                <RightImageBox2
                    image={alerts}
                    title="VIDEO ALERTS"
                    alt="Video Alerts throught the dashcam"
                    desc=" Our video alerts solution, was developed with the small/medium fleet operator in mind. It delivers driver safety and protection information to you within moments of an incident taking place. That may be a road collision an unsafe braking/speeding event a driver-initiated panic button or where someone just feels unsafe and wants to know you’re looking out for them. It utilises the lowest amount of cellular data of all our solutions, as a result is the most affordable Osrit Dashcam solution."
                />
                <hr />

                <div className="col-lg-12 col-md-12">
                    <div
                        className="text-center"
                        style={{ marginTop: 30, marginBottom: 10 }}
                    >
                        <h3 className="title  mt-20">
                            Benefits of Osrit Dash Cam
                        </h3>
                    </div>
                </div>
                <section className="custom-content-02 white-bg-bg page-section-ptb">
                    <div className="container">
                        <div className="row">
                            {features.map((feature, i) =>
                                this.feature(feature, i)
                            )}
                        </div>
                    </div>
                </section>
                <section
                    className="page-sidebar page-section-ptb-b"
                    style={{
                        marginTop: 40,
                        paddingTop: 30,
                        paddingBottom: 20,
                        backgroundColor: "#007fe40d"
                    }}
                >
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-lg-4 col-md-4">
                                <p>
                                    <i className="fa fa-camera" /> Live
                                    Streaming
                                </p>
                                <p>
                                    <i className="fa fa-bell" /> Event
                                    Notifications
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <p>
                                    <i className="fa fa-map-marker" /> Location
                                    Tracking
                                </p>
                                <p>
                                    <i className="fa fa-shield" /> Surveillance
                                    / Security
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <p>
                                    <i className="fa fa-file-text" /> Behaviour
                                    Reporting
                                </p>
                                <p>
                                    <i className="fa fa-cloud-upload" />{" "}
                                    On-Demand Video Upload
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Banner2 />
            </Container>
        );
    }
}

export default Dashcam;
