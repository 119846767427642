import React from 'react';

class Content extends React.Component {
    render() {
        return (<div className="policy_content" style={{ textAlign: "justify" }}>
            <h3 className="text-center policy_header">Privacy Policy</h3>
            <div>Our Privacy Policy is designed to assist you in understanding how we collect and use the personal information you provide to us and to assist you in making informed decisions when using our site and our products and services. This statement is made in the light of the requirements of the Data Protection in order to alert you to the data processing practices which will govern the processing of your data.</div>

            <h4 className="policy_title">How do we collect information?</h4>
            <div>We will obtain personal information from you when you register with us, make an application to our bursary schemes, or otherwise provide us with personal information.</div>

            <h4 className="policy_title">What type of information do we collect?</h4>
            <div>The types of information collected may include names, e-mail address, telephone number, and personal details pertaining to your circumstances if you complete an application form. This also includes the contact details of any named person you disclose in your application.</div>

            <h4 className="policy_title">How do we use this information?</h4>
            <div>We will use your personal information to provide you with the services or provide information you have requested and for administration purposes. We may need to share your information with our partners and associated organisations, who will treat the information with the strictest confidence in accordance with this privacy policy. Failure to provide certain personal data may affect the services we are able to provide to you. The data processing is carried out using computers and / or IT related tools, following organisational procedures. The data may be available to certain persons involved with the operations of the site or third party technical service providers, hosting providers appointed. We may want to share your information with other organisations that are supportive of our aims and objectives or for publicity. Your authorisation for this will be sought before any information is released. You can change your mind at any time by contacting us at the postal or email address above.</div>

            <h4 className="policy_title">Do we use 'Cookies'?</h4>
            <div>Cookies are text files, which identify a user's computer to our server. Cookies in themselves do not identify the individual user, just the computer used. Our website uses cookies for collecting user information. Any information collated from cookies will be used in the same way as detailed in our Data Protection policy. Most browsers allow you to reject cookies - consult the help section of the browser toolbar. Information on controlling cookies or rejecting cookies is available from several web sites, e.g. http://www.aboutcookies.org</div>

            <h4 className="policy_title">How do we protect personal information?</h4>
            <div>We use a secure server and also take appropriate measures to ensure that the information disclosed to us is kept secure, accurate and up to date and kept only for so long as is necessary for the purposes for which it is used.</div>

            <h4 className="policy_title">Will we disclose the information we collect to outside parties?</h4>
            <div>As indicated above, we may pass your information to our partners and associated organisations, and, if you have consented, to other organisations who may support our objectives. The most common form of this is tracking the progress of bursary recipients and sharing this with their allocated sponsor. We may also need to disclose your information if required to do so by law.</div>

            <h4 className="policy_title">Your consent</h4>
            <div>By providing us with your personal data, including sensitive personal data such as on your health and personal circumstances, you consent to the collection and use of any information you provide in accordance with the above purposes and this privacy statement. You also consent to our transferring your information to countries or jurisdictions which do not provide the same level of data protection, if required for legal purposes. If we do make such a transfer, we will, if appropriate, put a contract in place to ensure your information is protected.</div>

            <h4 className="policy_title">Right of access</h4>
            <div>You have the right to ask for a copy of the information we hold about you and to have any inaccuracies in your information corrected.</div>

            <h4 className="policy_title">Changes</h4>
            <div>If your personal details change, please help us to keep your information up to date by notifying us at the above address, or by contacting us on email to contactus@asritsolutions.com We reserve the right to amend this privacy statement. If we do so, we will post notice of the change on our website and you will be deemed to have accepted such changes.</div>
        </div>)
    }
}

export default Content;
