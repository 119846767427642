import React from "react";
import { Provider } from "react-redux";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import Products from "./containers/Products";
import ProductDetail from "./containers/ProductDetail";
import Gps from "./containers/Gps";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Footer from "./containers/Footer";
import TermsOfService from "./containers/TermsOfService";


const App = ({ store }) => (
    <Provider store={store}>
        <Router>
            <React.Fragment>
                <div style={{ minHeight: 700 }}>
                    <Route exact path={AppRoutes.products} component={Products} />
                    <Route exact path={AppRoutes.product} component={ProductDetail} />
                    <Route exact path={AppRoutes.gps} component={Gps} />
                    <Route exact path={AppRoutes.policy} component={PrivacyPolicy} />
                    <Route exact path={AppRoutes.terms} component={TermsOfService} />
                    <Route exact
                        path={"/"}
                        component={Home}
                    />
                    <Redirect to="/" />


                </div>
                <Footer />
            </React.Fragment>
        </Router>
    </Provider>
);

export default App;
