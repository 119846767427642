
import cp01 from "../images/cp01.jpg";
import cp2h from "../images/cp2h.png";
import cp03 from "../images/cp03.jpg";
import cp04 from "../images/cp04.png";
import cp05 from "../images/cp05.png";
import lte1 from "../images/lte1.jpg";
import lte2 from "../images/lte2.jpg";
import lte3 from "../images/lte3.png";
import lte4 from "../images/lte4.png";
import lte5 from "../images/lte5.jpg";
import lte6 from "../images/lte6.jpg";
import cp401 from "../images/cp401.png";
// import cp402 from "../images/cp402.jpg";
import cp403 from "../images/cp403.png";
import cp4 from "../images/cp4.png";
import cp42 from "../images/cp42.png";
import cp8 from "../images/cp8.png"
import cp82 from "../images/cp82.jpg"
import cp83 from "../images/cp83.jpg"
import cp84 from "../images/cp84.jpg"
import crx8 from "../images/crx8.png"
import cp86 from "../images/cp86.jpg"
import cp87 from "../images/cp87.jpg"



export const products = {
    "2-channel": {
        "benefits": "The 2 channel features a 1080p HD wide angle (150°) lens to provide a comprehensive view of any road event. The 2 channel will record exactly what happened before, during and after an incident. It does this by recording the following information: Drivers view of the road (and optionally the driver with the plug n play 2nd camera optino), Vehicle Location, Impact Force of Accident, Audio, & Driving Style (speed, acceleration, braking, & steering) .This evidence can protect a driver from many of the issues faced on the roads today: Insurance fraud, Staged Accidents, False/Exaggerated Whiplash Claims, Conflicting Reports of Actual Events, Driving Offense Allegations (speeding, traffic signal violations, swerving, etc).When a SIM card is inserted into the 2 channel (or connected via 2 channel built-in WiFi), 2 channel is able to send video and driving data wirelessly. Customers can use the 2 channel to transmit safety critical video and data via email, directly to a server (SmartWitness server software required), or integrate with Telematics Software (Please contact us for a list of compatible telematics software). The 2 channel can also be used as a stand-alone system utilizing the removable SD card to review video & data.",
        "features": ["HD 150° Wide Angle Lens on primary Camera",
            "Optional 2nd Camera can be connected (plug n play)",
            "Dual Record mode (Continuous + Event)",
            "Built-in microphone for audio recording",
            "Built-in speaker for audible driver alerting",
            "Connects directly to vehicle ignition power, automated operation.",
            "Tamper-Resistant Design",
            "Up to 128GB local storage (SD)",
            "GPS data records and transmits continuously to provide location data, vehicle speed, and accurate time/date",
            "Adjustable Resolution & Frame Rate",
            "Built-in G-Shock & Gyro Sensors",
            "Alarm I/O for triggering events and recording (i.e. door open, lights on, horn, etc.)",
            "Optional LTE transmission when active SIM card is inserted into 2 channel",
            "Optional WiFi transmission",
            "PC Analysis Software Included",
            "Built-in 4G/LTE: transmits video files in real-time after an incident",
            "Built-in WiFi: transmits data once 2 channel connects to WiFi AP (or continuously if connected to hotspot in the vehicle)",
            "Request HD video on demand from anywhere",
            "Filter Data Search by Time/Date, Event, Vehicle Speed, G-Force level",
            "MP4 Conversion Tool. Data Remains Watermarked.",
            "Privacy masking feature for blurring out faces or license plate numbers",
            "Google Maps Integration for Route Tracking",
            "Advanced Driver Behavior Analysis & Reporting Software",
            "Save/Print Event Reports",
            "OTA Software & Firmware Updates",
            "API available for telematics/insurance software integration."
        ],
        "box": ["2 channel Commercial Dash Camera",
            "Adjustable Winshield Bracket Mount with 3M adhesive pad",
            "SIM/SD card security cover with lock and key",
            "Permanently connected lead cables for power/junction box, 2nd Camera, & RS232 accessory input",
            "INT1-T Power Cable with unterminated connections Power(BAT+), Ignition, and Ground(BAT-). Two I/O trigger inputs.",
            "Adhesive Cable Clips for clean installation",
            "Quick Start Guide",
            "SD card & SIM card"],
        "optional": ["Secondary Camera: 5V plug & play camera for direct connection to 2 channel. Various Camera options available",
            "Extra SD Storage: up to 128GB SD storage",
            "SW-PB: Remote panic button for 2 channel. Connects to INT1-T NC/NO input trigger",
            "(COMING SOON)OBD data cableConnects serial input on 2 channel to OBDII port on vehicle to record speed, rpm, throttle %, engine temp, engine load, MAF air flow rate",
            "(COMING SOON)RFID reader: Connects Driver ID RF card reader to 2 channel serial port"],

        "images": [
            cp01, cp2h, cp03, cp04, cp05
        ],
    },
    "2-channel-lte": {
        "benefits": "The 2 channel(LTE) features a 1080p HD wide angle (150°) lens to provide a comprehensive view of any road event. The 2 channel(LTE) will record exactly what happened before, during and after an incident. It does this by recording the following information: Drivers view of the road (and optionally the driver with the plug n play 2nd camera optino), Vehicle Location, Impact Force of Accident, Audio, & Driving Style (speed, acceleration, braking, & steering) .This evidence can protect a driver from many of the issues faced on the roads today: Insurance fraud, Staged Accidents, False/Exaggerated Whiplash Claims, Conflicting Reports of Actual Events, Driving Offense Allegations (speeding, traffic signal violations, swerving, etc).When a SIM card is inserted into the 2 channel(LTE) (or connected via 2 channel(LTE) built-in WiFi), 2 channel(LTE) is able to send video and driving data wirelessly. Customers can use the 2 channel(LTE) to transmit safety critical video and data via email, directly to a server (SmartWitness server software required), or integrate with Telematics Software (Please contact us for a list of compatible telematics software). The 2 channel(LTE) can also be used as a stand-alone system utilizing the removable SD card to review video & data.",
        "features": ["HD 150° Wide Angle Lens on primary Camera",
            "Optional 2nd Camera can be connected (plug n play)",
            "Dual Record mode (Continuous + Event)",
            "Built-in microphone for audio recording",
            "Built-in speaker for audible driver alerting",
            "Connects directly to vehicle ignition power, automated operation.",
            "Tamper-Resistant Design",
            "Up to 128GB local storage (SD)",
            "GPS data records and transmits continuously to provide location data, vehicle speed, and accurate time/date",
            "Adjustable Resolution & Frame Rate",
            "Built-in G-Shock & Gyro Sensors",
            "Alarm I/O for triggering events and recording (i.e. door open, lights on, horn, etc.)",
            "Optional LTE transmission when active SIM card is inserted into 2 channel(LTE)",
            "Optional WiFi transmission",
            "Playback & Analysis",
            "PC Analysis Software Included",
            "Built-in 4G/LTE: transmits video files in real-time after an incident",
            "Built-in WiFi: transmits data once 2 channel(LTE) connects to WiFi AP (or continuously if connected to hotspot in the vehicle)",
            "Request HD video on demand from anywhere",
            "Filter Data Search by Time/Date, Event, Vehicle Speed, G-Force level",
            "MP4 Conversion Tool. Data Remains Watermarked.",
            "Privacy masking feature for blurring out faces or license plate numbers",
            "Google Maps Integration for Route Tracking",
            "Advanced Driver Behavior Analysis & Reporting Software",
            "Save/Print Event Reports",
            "OTA Software & Firmware Updates",
            "API available for telematics/insurance software integration."],
        "images": [lte1, lte2, lte3, lte4, lte5, lte6],
        "box": ["2 channel(LTE) Commercial Dash Camera",
            "Adjustable Winshield Bracket Mount with 3M adhesive pad",
            "SIM/SD card security cover with lock and key",
            "Permanently connected lead cables for power/junction box, 2nd Camera, & RS232 accessory input",
            "INT1-T Power Cable with unterminated connections Power(BAT+), Ignition, and Ground(BAT-). Two I/O trigger inputs.",
            "Adhesive Cable Clips for clean installation",
            "Quick Start Guide",
            "SD card & SIM card"],
        "optional": ["Secondary Camera: 5V plug & play camera for direct connection to 2 channel(LTE). Various Camera options available",
            "Extra SD Storage: up to 128GB SD storage",
            "SW-PB: Remote panic button for2 channel(LTE). Connects to INT1-T NC/NO input trigger",
            "(COMING SOON)OBD data cableConnects serial input on 2 channel(LTE) to OBDII port on vehicle to record speed, rpm, throttle %, engine temp, engine load, MAF air flow rate",
            "(COMING SOON)RFID reader: Connects Driver ID RF card reader to 2 channel(LTE) serial port"]
    },
    "4-channel": {
        "benefits": "The 4 channel is a powerful, compact and discreet 4 camera recorder for recording up to 4 cameras with audio. The 4 channel will record exactly what happened before, during and after an incident. It does this by recording the following information: Drivers view of the road, Vehicle Location, Impact Force of Accident, Audio, & Driving Style (speed, acceleration, braking, & steering).This evidence can protect a driver from many of the issues faced on the roads today: Insurance fraud, Staged Accidents, False/Exaggerated Whiplash Claims, Conflicting Reports of Actual Events, Driving Offense Allegations (speeding, traffic signal violations, swerving, etc).4 channel is small, lightweight, easy to use, and simple to install compared to other complex and expensive mobile DVRs. 4 channel can be easily installed inside a glove box or purchased with a locking case to prevent unauthorised access to the recorded data. An LCD monitor is not required for use, but can be added to provide added visibility in and around the vehicle for added security and safer operation.",
        "features": ["720P recording on all CH 1-3, D1 on CH 4",
            "128GB SD storage capacity, record up to 150 hours of footage on 4 camera setup",
            "Various camera options: wide angle, weatherproof, infrared, dome, side view, rugged, reverse cameras, etc.",
            "Dual Record mode (Continuous + Event)",
            "Optional Audio Recording",
            "Connects directly to vehicle ignition power, automated operation.",
            "Tamper-Resistant locking case available; Key required to access SD card Data",
            "GPS data records full time to provide location data, vehicle speed, and accurate time/date",
            "Adjustable Resolution & Frame Rate",
            "Built-in G-Shock Sensor and Gyro (adjustable sensitivity)",
            "Three 12V alarm input triggers for advanced event recording. Two 5V alarm outputs for 3rd party systems integration.",
            "Wired remote control & Emergency/Panic button for driver activated event recording",
            "Auto SD card format feature. 4 channel automatically detects SD error/corruption and auto-formats itself and begins recording again.",
            " Delay power shutdown feature enables recording for up to 24 hours after ignition off",
            " Built-in temperature logic for improved performance in high-temp environments",
            "REST API available for system integration",
            "PC Analysis Software Included",
            "Compatible with SmartWitness Smart API for AVL/Telematics Integration",
            "Filter Data Search by Time/Date, Event, Vehicle Speed, G-Force level",
            "MP4 Conversion Tool. Data Remains Watermarked.",
            "Google Maps Integration for Route Tracking",
            "Google Earth Export Tool for Advanced Route Tracking and Archiving",
            "Privacy masking feature for blurring out faces or license plate numbers",
            "31 day Vehicle Tracking History, showing vehicle location, speed and driving style regardless if event video was recorded or not",
            "Save/Print Event Reports",
            "OTA Software & Firmware Updates",
            "With optional LCD monitor connected, view all four cameras at once or playback recorded videos in the vehicle",
        ],
        "images": [
            cp401, cp403, cp4, cp42
        ],
        "box": ["4 channel recorder",
            "Power cable harness with alarm trigger wires",
            "GPS receiver",
            "Remote Control/Panic Button",
            "3M foam adhesive, adhesive velcro, & adhesive cable clips",
            "Mic & G-Shock Sensor (built-in to 4 channel recorder)",
            "Omni-Directional wired microphone extension with 9' cable",
            "Video output cable for connecting LCD monitor",
            " Quick Start Guide"
        ],
        "optional": ["4 channel Cameras: 12V plug & play cameras for direct connection to 4 channel channels 1-4. Various Camera and Monitor options available"]
    },
    "8-channel": {
        "benefits": "The Osrit Dashcam 8 channel offers High Definition in-vehicle CCTV recording for up to 8 cameras. 8 channel provides fleet operators with an effective way to monitor and manage their remote assets whilst in the field. In the event of an incident and/or allegation the 8 channel HD mobile will aid in the investigation process whilst reducing potential cost and exposure. In-Vehicle CCTV delivers actual video footage in the event of a claim, encourages safer more fuel-efficient driving, monitors driving behaviours and enhances the overall safety and security for the driver, the fleet operator and other road users.The solution includes a built-in 3-Axis G-Sensor, GPS, 8 alarm input triggers, flexible and powerful storage using an SSD (up to 1TB) and SD Card backup storage (up to 128GB). The 8 channel is slim and compact, only 7 x 7 x 2 and is connected directly to the vehicle's battery power and ignition for fully automated operation. Optionally connect a dashboard mounted LCD display to provides the driver with a real-time 360° view of the interior and exterior of the vehicle.",
        "features": ["Up to 5 Megapixel recording supported. Resolution adjustable from 5MP (2592x1944) to SD (720x480) and everything in between (Camera lens must support the set resolution)",
            "240FPS (frames per second) when recording at 720p (divided amongst all camera channels). 180FPS at 1080p; 24FPS at 5MP; 60FPS at 4MP; 72FPS at 3MP",
            "Various camera options available",
            "Dual Record mode (Continuous + Event)",
            "Optional Audio Recording (8 audio channels max)",
            "Connects directly to vehicle ignition power, automated operation.",
            "Tamper-Resistant locking cover and secure cover for rear cable inputs; addtional Key required to remove SSD",
            "GPS data records full time to provide location data, vehicle speed, and accurate time/date",
            "Adjustable Resolution & Frame Rate",
            "Built-in G-Shock Sensor (adjustable sensitivity)",
            "8 Alarm input triggers for advanced event recording",
            "Optional WiFi and/or 3G/LTE Cellular connectivity for realt-time video upload and live GPS tracking",
            "Battery backup included for recording and notification of incident in case of abrupt power loss",
            "PC Analysis Software Included",
            "Optional WiFi: transmits the pending events and location data once 8 channel connects to WiFi AP",
            "Request HD video on demand from anywhere",
            "Filter Data Search by Time/Date, Event, Vehicle Speed, G-Force level",
            "MP4 Conversion Tool. Data Remains Watermarked.",
            "Privacy masking feature for blurring out faces or license plate numbers",
            "Google Maps Integration for Route Tracking",
            "Advanced Driver Behaviour Analysis & Reporting Software",
            "Save/Print Event Reports",
            "OTA Software & Firmware Updates",
            "API available for integration with Telematics/AVL software.",
            "With optional LCD monitor connected, view all camera channels at once or playback recorded videos in the vehicle",
        ],
        "images": [
            cp8, cp82, cp83, cp84, crx8, cp86, cp87
        ],
        "box": ["8 CHANNEL DVR",
            "Power cable harness",
            "GPS receiver",
            "Alarm Trigger I/O Harness",
            "USB Cable (for connecting optional WiFi modem)",
            "Car Signal harness (brake, turn signals, A/V)",
            "Steel L bracket mounts and Rear Bracket Cover",
            "Removable SSD 128GB",
            "Backup Battery included for protection against abrupt power loss",
            "Two AHD Camera Harnesses, 4 pin aviation connector(ch 1-4 and ch 5-8)",
            "Keyset and Screws"
        ],
        "optional": ["AHD Cameras, up to 8: 12V plug & play camera for direct connection to 8 channel. Various Camera options available.",
            "Extra SSD Storage: up to 1TB",
            "Extra SD Storage: up to 128GB",
            "8 channel-PB: Remote panic button for 8 channel. Connects to I/O harness",
            "SV7QLCD: 7 LCD Monitor with Multi Camera Display. Adjustable bracket mount designed for mounting on dashboard or roof lining",
            "INT4(WiFi): 802.11 b/g/n USB Wi-Fi modem, up to 300ft range. Connects to USB cable included with 8 channel",
            "INT4(LTE): LTE modem for live tracking and video upload. Avaiable for AT&T and Verizon only. Connects to USB2 input on rear panel",
            "SVA-CNVRT-AC: RCA/DC adaptor to use your own analog 12V cameras",
            "8 channel-OBD : (ETA Q2 2019) OBD Interface Cable and interpreter module for receiving information from vehicle: Speed, RPM, Engine Loading, Battery Voltage, Engine Temperature, Throttle Position Status(Emergency Braking, Brake, Clutch, Cruise Control), MAF, Distance, and FU ( Average Fuel Economy)"]
    },
}
