import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import initialState from "./utils/serverProps";
// import createHistory from 'history/createBrowserHistory'
const createHistory = require("history").createBrowserHistory;

const middleware = [thunkMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createHistory();
export default createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);
