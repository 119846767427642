import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import AppRoutes from "../../AppRoutes";
import "./footer.scss";

class Footer extends React.Component {
    render() {
        const today = new Date();
        return (
            <Row id="homeFooter">
                <Col xs={12} md={6}>
                    © {today.getFullYear()},{" "}
                    <a
                        href="http://www.asritsolutions.com"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Asritsolutions LLC.
                    </a>{" "}
                    All Rights Reserved.
                </Col>
                <Col className="footer_right" xs={12} md={6}>
                    <Link to={AppRoutes.policy} className="">
                        {"Privacy Policy"}
                    </Link>{" "}
                    <Link to={AppRoutes.terms} className="">
                        {"Terms of Service"}
                    </Link>
                </Col>
            </Row>
        );
    }
}

export default Footer;
