import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

class LeftImageBox extends React.Component {
    render() {
        const { image, title, desc, desc2, alt, list, link = "", shadow = true } = this.props
        return (
            <Row className="image-box-container"
                style={{ margin: 0 }}>
                <Col />
                <Col sm="12" md={5}
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                    <img className={shadow ? "styled img-mobile-view" : "img-mobile-view"} style={{ marginTop: 20, marginBottom: 20, maxHeight: 350, maxWidth: "100%", }}
                        src={image} alt={alt} />
                </Col>

                <Col md="5" sm="12" style={{
                    paddingTop: 60,
                    paddingBottom: 60,
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div className="text-center" style={{ width: "100%" }}>
                        <h1 className="text-center h1" style={{ width: "100%", marginBottom: 15, fontSize: 30 }}>{title}</h1>
                        <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc}</div>
                        <div className="text" style={{ marginBottom: 15, justifyContent: "center", textAlign: 'justify' }}>{desc2}</div>
                        {list && Array.isArray(list) && list.length > 0 && (
                            <ul style={{ marginLeft: 30, textAlign: "justify", fontSize: 14, listStyle: 'none' }}>
                                {list.map((item) => { return (<li style={{ lineHeight: 1.6, textAlign: "justify" }}><i className="fas fa-long-arrow-alt-right" /> {item}</li>) })}
                            </ul>
                        )}
                        <div className="home_card_button_wrapper text-center">
                            {link ? <Link
                                to={link}
                                className="home_card_button btn btn-outline-secondary"
                            >
                                MORE INFO
                        </Link> : null}
                        </div>

                    </div>
                </Col>
                <Col />
            </Row>
        )
    }
}

export default LeftImageBox;
