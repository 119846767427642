import React from "react";
import Banner from "../Banner";
import Header from "../Header";
import { Container } from "reactstrap";
import GpsContent from "./GpsContent";
// import TextWithTitle from "../common/TextWithTitle";
import map from "../../images/map.png"
import LeftImageBox2 from "../common/LeftImageBox2";
import "./gps.scss"

class Gps extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <Container
                style={{
                    padding: 0
                }}
                fluid={true}
                id="gpsWrapper"
            >
                <Banner />
                <Header curr={"gps"} />
                <div className="gps-img"> 
                <LeftImageBox2 image={map} title="GPS - Fleet Tracking"
                    alt="load information overload servive"
                    desc="Osrit is continually improving to meet the 
                    needs of today's high demand trucking and brokerage 
                    professionals. Osrit is designed for customers advanced 
                    features of Tracking and Remote Immobilization that keeps
                     the track of various features of vehicles like ignition, 
                     speed over limit etc."
               /></div>
                <GpsContent />
            </Container >
        );
    }
}

export default Gps;
