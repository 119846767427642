import React from 'react';
import Banner from '../Banner';
import Header from '../Header';
import Content from './Content';
import './privacy.scss';

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (<div id="privacyWrapper">
            <Banner />
            <Header />
            <Content />
        </div>)
    }
}

export default PrivacyPolicy;
